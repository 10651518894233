import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allWpPost.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <ol style={{ listStyle: `none` }}>
        {posts.map((post) => (
          <li key={post.slug}>
            <article
              className="post-list-item"
              itemScope
              itemType="http://schema.org/Article"
            >
              <header>
                <h2>
                  <Link to={`/posts/${post.slug}`} itemProp="url">
                    <span itemProp="headline">{post.title}</span>
                  </Link>
                </h2>
                <small>{post.date}</small>
              </header>
              {/*<section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: post.excerpt,
                  }}
                  itemProp="description"
                />
              </section>*/}
            </article>
          </li>
        ))}
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allWpPost(sort: { fields: [date], order: DESC }) {
        nodes {
            title
            excerpt
            slug
            date(formatString: "MMMM DD, YYYY")
        }
    }
  }
`
